export default offence => {
  return [
    {
      title: 'Протоколы',
      props: {
        date: 'offence_protocol_object_owner_date',
        number: 'offence_protocol_object_owner_number',
        status: 'status_name',
        penalty: 'amount_of_penalty_object_owner',
        penalty_payment: 'amount_of_penalty_object_owner_paid'
      },
      offence
    }
  ];
};
